<template>
  <NavbarItem />
  <router-view />
  <FooterItem />
</template>

<script>
// @ is an alias to /src
import NavbarItem from "@/components/NavbarItem.vue";
import FooterItem from "@/components/FooterItem.vue";

export default {
  components: {
    NavbarItem,
    FooterItem,
  },
};
</script>

<style>
@font-face {
  font-family: "Oswald-Regular";
  src: local("Oswald-Regular"),
    url(./fonts/Oswald-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Caveat-SemiBold";
  src: local("Caveat-SemiBold"),
    url(./fonts/Caveat-SemiBold.ttf) format("truetype");
}
</style>
