<template>
  <div
    class="bg-image postion-relative"
    :style="{
      padding: '30px',
      'min-height': '400px',
      'min-height': '400px',
      'padding-top': '30px',
      backgroundImage:
        'url(' + require('@/assets/img/kulturistsystemrelevant.jpg') + ')',
    }"
  >
    <h4
      id="sub-header"
      class="position-absolute bottom-0 start-0 p-3 ps-lg-5 ms-lg-5"
    >
      <span id="header">#gesichtzeigen</span
      ><strong>
        Für eine nachhaltige Verbesserung der Arbeitsbedingungen in der Lehre an
        Musikhochschulen</strong
      >
    </h4>
  </div>
  <MDBContainer class="mt-lg-5 mt-3">
    <MDBRow>
      <MDBCol sm="7" class="h-100">
        <h3 class="header_font">#gesichtzeigen</h3>
        Die Initiative der Lehrbeauftragtenvertretungen der Hochschule für Musik
        “Carl-Maria von Weber” Dresden und der Hochschule für Musik und Theater
        “Felix Mendelssohn Bartholdy” Leipzig stehen für eine Reform der
        Lehrstruktur an den Sächsischen Musikhochschulen.
        <br /><br />Gemeinsam mit den Studierenden der KSS, den Gewerkschaften
        GEW, Ver.di und DOV, den Professoren und Rektoren wollen wir auf die
        Schieflage der jetzigen Struktur hinweisen und für eine nachhaltige
        Verbesserung der Lehre sorgen. In den bisherigen Programmen auf Landes-
        und Bundesebene wie zum Beispiel der “Zukunftsvertrag Studium und Lehre
        stärken” bleibt die Besonderheit der Lehrbeauftragten an
        Musikhochschulen unbeachtet.
      </MDBCol>
      <MDBCol sm="5" class="h-1">
        <img
          src="@/assets/img/kopf.jpg"
          class="pt-3 pt-sm-0 img-fluid"
          alt="kopf"
        />
      </MDBCol>
    </MDBRow>
    <MDBRow class="mt-4">
      <MDBCol sm="5" class="h-1">
        <img
          src="@/assets/img/stuehle.jpg"
          class="pt-3 pt-sm-0 img-fluid"
          alt="stuehle"
        />
      </MDBCol>
      <MDBCol sm="7" class="h-100 mt-3 mt-sm-0">
        <h3 class="header_font">Die eigentliche Idee des Lehrauftrags</h3>
        Der Lehrauftrag an den Musikhochschulen war ursprünglich für den
        Wissenstransfer aus der Praxis sowie die Deckung von Lehrbedarfen, die
        nur unregelmäßig benötigt werden, gedacht. Der “typische Lehrauftrag”
        war zum Beispiel, wenn ein festangestellter Musiker aus einem Orchester
        nebenberuflich sein Wissen an den Musikhochschulen vermittelt oder wenn
        sogenannte “Orchideenfächer”, also Fächer, die nur sehr wenig Lehrumfang
        benötigen, mit dem Einsatz von Lehrbeauftragten abgedeckt werden können.
        Ausdrücklich war die Idee, einen vorübergehenden Lehrbedarf zu decken.
        <br />
        <br />
        <h3 class="header_font">Der Lehrauftrag heute</h3>
        In den letzten dreißig Jahren haben sich viele Studieninhalte verändert:
        die Hochschulen sind nicht mehr nur eine “Orchesterakademie”, in der man
        auf eine Orchesterstelle vorbereitet wird, sondern es gibt mittlerweile
        sehr viele Studierende in den Bereichen des Lehramts, des Jazz und der
        Instrumental- und Gesangspädagogik.
      </MDBCol>
    </MDBRow>
    <MDBRow class="mt-4">
      <MDBCol sm="12" class="h-100">
        Leider wurden auch diese Lehrbedarfe mit den “günstigeren”
        Lehrbeauftragten gedeckt, obwohl die Lehrenden oftmals keine Anstellung
        haben sondern selbstständig und hauptberuflich in der Lehre tätig sind.
        In einer
        <a href="/files/festanstellung.pdf" target="_blank"
          >aktuellen Umfrage</a
        >
        geben zwei Drittel der Lehrbeauftragten an, keine Festanstellung zu
        haben. Für ein Drittel ist der Lehrauftrag die Haupteinnahmequelle. Dies
        widerspricht der Grundidee, dass der Lehrauftrag nur ein Nebenberuf ist.
        Mehr als die Hälfte sind seit mehr als 10 Jahren im Lehrauftrag tätig,
        was zeigt, dass es hier nicht um eine vorübergehende Deckung des
        Lehrbedarfs geht, sondern die Lehre dauerhaft an Lehrbeauftragte
        ausgegliedert wurde. 50 % geben an, in der grundständigen Lehre wie
        Klavier, Theorie und Korrepetition tätig zu sein. <br /><br />
        <h3 class="header_font">Für eine faire Lehre braucht es:</h3>
        Dauerstellen für Daueraufgaben: der Anteil der Lehrbeauftragten muss auf
        25% reduziert werden und dabei die bestehenden Lehraufträge in
        sozialversicherungspflichtige Anstellungen überführt werden. Dieser
        Anteil erlaubt den Hochschulen einerseits auf schwankende Bedarfe zu
        reagieren und garantiert andererseits den Lehrenden eine soziale
        Absicherung. Hierfür braucht es an der Hochschule für Musik Dresden ca.
        30 und an der Hochschule für Musik und Theater Leipzig ca. 60
        Mittelbaustellen, die als Teilzeitstellen ausgeschrieben werden sollten,
        damit möglichst viele langjährige Lehrbeauftragte eine Perspektive
        bekommen.
      </MDBCol>
    </MDBRow>
    <MDBRow class="mt-4">
      <MDBCol sm="5" class="h-1">
        <img
          src="@/assets/img/geige.jpg"
          class="pt-3 pt-sm-0 img-fluid"
          alt="stuehle"
        />
      </MDBCol>
      <MDBCol sm="7" class="h-100 mt-3 mt-sm-0">
        <h3 class="header_font">
          Angemessene Vergütung - jetzt und in Zukunft
        </h3>
        Die Vergütung der Lehrbeauftragten muss in Honorarrichtlinien formuliert
        werden, die dem Risiko der Selbstständigkeit tatsächlich Rechnung trägt.
        Die für die Hochschulen notwendige Flexibilität darf nicht auf Kosten
        der Lehrbeauftragten kalkuliert werden. Dazu muss das Etat der
        Hochschulen dauerhaft erhöht werden. Dieses Etat muss an die Entwicklung
        der Tariferhöhungen im öffentlichen Dienst gekoppelt werden, um einen
        Inflationsausgleich in Zukunft abfangen zu können und die
        Honorarentwicklung der Entlohnung der im öffentlichen Dienst
        Beschäftigten anzugleichen. Falls es zu einem Aufwuchs der
        Mittelbaustellen in den Hochschulen kommen sollte, ist das jetzige
        Budget für eine angemessene Vergütung zunächst ausreichend. Es Bedarf
        dann eine Kopplung an die Tarifrunden des Tarifvertrags der Länder.
      </MDBCol>
    </MDBRow>
    <MDBRow class="mt-4">
      <MDBCol sm="12" class="h-100">
        <h3 class="header_font">Rechtliche Sicherheit</h3>
        Der Lehrauftrag kann in Sachsen neben eines privatrechtlichen Vertrags
        auch als Verwaltungsakt ausgestaltet. Im Rahmen der Novellierung des
        Hochschulfreiheitsgesetz braucht es ein klares Bekenntnis zum
        privatrechtlichen Vertrag, um das Aushebeln der rechtlichen
        Möglichkeiten einen Riegel vorzuschieben. Durch den Verwaltungsakt
        greifen Schutzmechanismen, die in solchen Rechtsgeschäften üblich sind,
        nicht. Weitere Informationen dazu gibt es bei der
        <a
          href="https://jimdo-storage.global.ssl.fastly.net/file/e73ae723-6ecc-443d-aacd-94e69159e08a/Stuttgarter%20Reformatio.jpeg"
          target="_blank"
          >Bundeskonferenz der Lehrbeauftragten BKLM</a
        >. Alle Vorschläge zur Novellierung des SächsHSFG
        <a href="files/gesetzesvorschlag.pdf" target="_blank">hier</a> und eine
        sehr gute Einführung in die Thematik des Lehrauftrags auf Bundesebene
        gibt die GEW in ihrem
        <a
          href="https://www.gew.de/index.php?eID=dumpFile&t=f&f=31370&token=f660e4342021f1482b2ac2c0f9d727d6e8d9a4b1&sdownload=&n=Lehrbeauftragte_2015_A5_web.pdf"
          target="_blank"
          >Flyer</a
        >
        und in der Übersicht der Deutschen Orchestervereinigung (DOV)
        <a href="files/Flyer-DOV-LB.pdf" target="_blank">hier</a>.
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import { MDBCol, MDBRow, MDBContainer } from "mdb-vue-ui-kit";

export default {
  name: "InitiativeView",
  components: {
    MDBCol,
    MDBRow,
    MDBContainer,
  },
  data() {
    return {
      msg: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.header_font {
  font-family: "Oswald-Regular";
  font-size: 21px;
}
#header {
  font-family: "Oswald-Regular";
  font-size: 2em;

  color: rgba(255, 255, 255, 1);
}

#sub-header {
  font-family: "Caveat-SemiBold";
  color: #fff;
  display: inline-block;
}
</style>
