import "mdb-vue-ui-kit/css/mdb.min.css";

import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import VueAgile from "vue-agile";
import moment from "moment";

import "dropzone/dist/dropzone.css";

const app = createApp(App);
app.use(router);
app.use(VueAgile);
app.use(moment);

app.mount("#app");
