<template>
  <div>
    <div
      class="text-center bg-image"
      :style="{
        padding: '30px',
        'padding-top': '30px',
        backgroundImage: 'url(' + require('@/assets/img/bg-header.jpg') + ')',
      }"
    >
      <img id="header_logo" src="@/assets/img/notes.png" />
      <div id="header_start" class="mt-1">#gesichtzeigen</div>
      <h3 id="sub-header_start" class="mb-3 mt-4">
        Für eine nachhaltige Verbesserung der Lehre an den sächsischen
        Musikhochschulen
      </h3>

      <a href="/mitmachen"
        ><MDBBtn
          color="primary"
          size="lg"
          class="me-2"
          style="background-color: #00b74a"
          >Mitmachen</MDBBtn
        ></a
      >
    </div>
    <MDBContainer class="mt-2 mt-sm-5">
      <MDBRow class="mx-lg-5 px-lg-5 mx-sm-0 px-sm-0">
        <MDBCol md="4" style="text-align: center" class="px-5">
          <div class="bg-image hover-zoom" style="">
            <a href="https://www.uni-sono.org/" target="_blank"
              ><img :src="require('@/assets/img/Home_Logo1.png')" width="210"
            /></a>
          </div>
        </MDBCol>
        <MDBCol md="4" style="text-align: center" class="px-5">
          <div class="bg-image hover-zoom">
            <a href="https://jazzverband-sachsen.de/" target="_blank"
              ><img :src="require('@/assets/img/Home_Logo2.jpg')" width="210"
            /></a>
          </div>
        </MDBCol>
        <MDBCol md="4" style="text-align: center" class="px-5">
          <div class="bg-image hover-zoom">
            <a href="https://www.bklm.org/" target="_blank"
              ><img :src="require('@/assets/img/Home_Logo3.jpg')" width="210"
            /></a>
          </div>
        </MDBCol>
      </MDBRow>

      <!--
      <MDBRow>
        <MDBCol md="6" style="padding: 5%">
          <img
            class="center-block"
            style="width: 100%; border: 4px solid #515151"
            src="@/assets/img/poster_1.png"
          />
        </MDBCol>
        <MDBCol md="6" style="padding: 5%">
          <img
            class="center-block"
            style="width: 100%; border: 4px solid #515151"
            src="@/assets/img/poster_2.png"
          />
        </MDBCol>
      </MDBRow>
      -->

      <MDBRow class="mt-4">
        <MDBCol sm="6" class="h-100">
          <MDBRow class="p-3 p-sm-0">
            Die Lehrbeauftragten an den sächsischen Musikhochschulen sichern
            seit Jahrzehnten einen Großteil des Lehrbedarfs an den
            Musikhochschulen ab. Leider fehlt es immer noch an einer fairen und
            auskömmlichen Vergütung. Eine berufliche Perspektive, soziale
            Absicherung sowie eine ausreichende Altersvorsorge ist aufgrund der
            niedrigen Honorare nicht möglich. Für eine nachhaltige Sicherung der
            hohen Qualität in der Ausbildung des künstlerischen Nachwuchs
            braucht es deshalb ein Umdenken in der Lehrstruktur und Ausstattung
            der Musikhochschulen:
          </MDBRow>

          <MDBRow class="ps-sm-0 mt-3">
            <MDBCol md="6" class="ps-md-0 pb-2 pb-md-0">
              <MDBCard class="h-100" border="primary">
                <MDBCardBody>
                  <MDBCardHeader
                    class="px-0 lead"
                    style="font-size: 18px; font-weight: 600"
                    ><strong
                      >Dauerstellen für Daueraufgaben</strong
                    ></MDBCardHeader
                  >
                  <MDBCardText>
                    Mehr Stellen im Mittelbau, damit der Lehrauftrag zu seiner
                    ursprünglichen Idee zurückgeführt werden: der
                    Wissenstransfer aus der Praxis.
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol md="6" class="pb-2 pb-md-0">
              <MDBCard class="h-100" border="primary">
                <MDBCardBody>
                  <MDBCardHeader
                    class="px-0 lead"
                    style="font-size: 18px; font-weight: 600"
                    ><strong>Fair statt prekär</strong></MDBCardHeader
                  >
                  <MDBCardText>
                    Faire Vergütung, die es den oftmals selbstständig Lehrenden
                    erlaubt, Rücklagen zu bilden und von ihrer Arbeit leben zu
                    können.
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol sm="6" class="h-100">
          <AgilSlider />
        </MDBCol>
      </MDBRow>

      <MDBRow style="margin-top: 50px">
        <MDBCol sm="6" class="h-100">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube-nocookie.com/embed/s8wR7A7ZJWw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </MDBCol>

        <MDBCol sm="6" class="h-100">
          <h3 style="margin-top: 15px">
            Unsere Demo am 21.11.22. vor dem Landtag!
          </h3>
          Wir haben unsere Position vor dem sächsischen Parlament kundgetan –
          jetzt liegt es bei den Politiker*innen im sächsischen Landtag.
          <br />Aber: wenn wir uns den Entwurf des Hochschulgesetzes anschauen,
          bleibt auch für uns noch einiges zu tun. Die Linksfraktion Sachsen mit
          ihrem kulturpolitischen Sprecher Franz Sodann hat uns mit ihrem Antrag
          für eine faire Lehre bereits maßgeblich unterstützt. Wir sind gespannt
          auf die Gespräche mit den Fraktionen von Grünen, SPD und CDU im
          Landtag.

          <div style="font-size: 14px; font-style: italic; margin-top: 10px">
            Danke an Lucas Pusch für das tolle Video. Danke für den Support:
            unisono, GEW, Bundeskonferenz der Lehrbeauftragten an
            Musikhochschulen, Jazzverband Sachsen e.V., KSS Konferenz
            Sächsischer Studierendenschaften für die Unterstützung vor Ort und
            Sebastian Haas für die Orga!
          </div>
        </MDBCol>
      </MDBRow>

      <MDBRow style="margin-top: 50px">
        <div v-if="mounted" :style="gridStyle" class="card-list">
          <div
            v-for="statement in statements.slice(0, 20)"
            :key="statement.id"
            class="card-item"
            @click="showDetails(statement)"
          >
            <div
              class="bg-image ripple hover-zoom"
              data-mdb-ripple-color="light"
            >
              <img
                :src="
                  'https://faire-lehre.de/faire_lehre_file/files/' +
                  statement.image
                "
                class="w-100"
              />

              <div
                class="mask"
                style="background-color: rgba(90, 150, 118, 0.4)"
              >
                <div
                  class="d-flex justify-content-center align-items-center h-100"
                >
                  <div class="text-justify">
                    <h5
                      class="text-white mb-0 text-justify"
                      style="text-align: center"
                    >
                      {{ statement.heading }}
                    </h5>

                    <p
                      class="text-white mb-0 text-justify"
                      style="
                        font-weight: 200;
                        font-size: 14px;
                        text-align: center;
                      "
                    >
                      <strong
                        >{{ statement.vorname }}
                        {{ statement.nachname }}</strong
                      >
                    </p>
                  </div>
                  <div
                    style="font-size: 14px"
                    class="rounded-3 px-2 m-2 bg-dark bg-gradient text-white bg-opacity-75 position-absolute bottom-0 end-0"
                  >
                    {{ statement.beruf }}
                  </div>
                </div>
              </div>
              <div class="hover-overlay">
                <div
                  class="mask"
                  style="background-color: rgba(251, 251, 251, 0.2)"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: center; padding: 60px">
          <a href="/wir-sind-dabei"
            ><MDBBtn
              color="primary"
              size="lg"
              class="me-2"
              style="background-color: #00b74a"
              >Mehr
            </MDBBtn></a
          >
        </div>

        <hr />
        <div style="padding-bottom: 40px">
          <div
            class=""
            style="
              word-wrap: break-word;
              -webkit-nbsp-mode: space;
              line-break: after-white-space;
              margin-bottom: 20px;
            "
          >
            <font size="2" class=""><br class="" /></font>
            <div class="">
              <span
                id="docs-internal-guid-a3ccb125-7fff-a902-b6bd-456964913e26"
                class=""
                ><font size="2" class=""
                  ><div
                    class=""
                    style="
                      line-height: 1.38;
                      text-align: justify;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      id="docs-internal-guid-3750bcd5-7fff-79ca-9cf2-e999c99afb75"
                      class=""
                      style="text-align: start"
                      ><div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1.2rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            padding-right: 20px;
                          "
                          >Offener Brief</span
                        >
                        <a
                          href="/files/Offener_Brief_20221121.pdf"
                          target="_blank"
                          ><MDBBtn
                            color="primary"
                            size="sm"
                            style="background-color: #00b74a"
                            >Download
                          </MDBBtn></a
                        >
                      </div></span
                    >
                  </div></font
                ></span
              >
            </div>
            <div
              class=""
              style="
                line-height: 1.38;
                text-align: justify;
                margin-top: 0pt;
                margin-bottom: 0pt;
              "
            >
              <span
                class=""
                style="
                  font-size: 9pt;

                  font-variant-ligatures: normal;
                  font-variant-east-asian: normal;
                  font-variant-position: normal;
                  vertical-align: baseline;
                  white-space: pre-wrap;
                "
                >21.11.2022</span
              >
            </div>
          </div>
          <span
            style="
              font-size: 1.2rem;
              font-weight: 700;
              font-variant-ligatures: normal;
              font-variant-east-asian: normal;
              font-variant-position: normal;
              vertical-align: baseline;
              white-space: pre-wrap;
            "
            >Offener Brief an <br />Staatsminister Sebastian Gemkow
            <br />Hochschulpolitische Sprecher der Fraktionen CDU, SPD, Grüne,
            Linke:
          </span>
          <span>
            <br />Oliver Fritsche (CDU) <br />Sabine Friedel (SPD) <br />Claudia
            Maicher (Grüne) <br />Anna Gorskih (Linke)
          </span>
          <div style="margin-top: 20px"></div>
          <span
            >Sehr geehrter Staatsminister Gemkow, sehr geehrte Sprecher der
            Fraktionen, <br /><br />Die Lehrbeauftragten an den sächsischen
            Musikhochschulen sichern seit Jahrzehnten einen Großteil des
            Lehrbedarfs an den Musikhochschulen ab. Leider fehlt es immer noch
            an einer fairen und auskömmlichen Vergütung. Eine berufliche
            Perspektive, soziale Absicherung sowie eine ausreichende
            Altersvorsorge ist aufgrund der niedrigen Honorare nicht möglich.
            Für eine nachhaltige Sicherung der hohen Qualität in der Ausbildung
            des künstlerischen Nachwuchses braucht es deshalb ein Umdenken in
            der Lehrstruktur und Ausstattung der Musikhochschulen:
            <br /><br /><u>Dauerstellen für Daueraufgaben</u> <br />Mehr Stellen
            im Mittelbau, damit der Lehrauftrag zu seiner ursprünglichen Idee
            zurückgeführt wird: der Wissenstransfer aus der Praxis.

            <br /><br /><u>Fair statt prekär</u> <br />Faire Vergütung, die es
            den oftmals selbstständig Lehrenden erlaubt, Rücklagen zu bilden und
            von ihrer Arbeit leben zu können. <br /><br />Die Initiative der
            Lehrbeauftragtenvertretungen der Hochschule für Musik “Carl-Maria
            von Weber” Dresden und der Hochschule für Musik und Theater “Felix
            Mendelssohn Bartholdy” Leipzig steht für eine Reform der
            Lehrstruktur an den Sächsischen Musikhochschulen.
            <br /><br />Gemeinsam mit den Studierenden der KSS, den
            Gewerkschaften GEW und Unisono, den Professoren und Rektoren wollen
            wir auf die Schieflage der jetzigen Struktur hinweisen und für eine
            nachhaltige Verbesserung der Lehre sorgen. In den bisherigen
            Programmen auf Landesund Bundesebene wie zum Beispiel der
            “Zukunftsvertrag Studium und Lehre stärken” bleibt die Besonderheit
            der Lehrbeauftragten an Musikhochschulen unbeachtet. <br /><br /><b
              >Die eigentliche Idee des Lehrauftrags</b
            >
            <br />Der Lehrauftrag an den Musikhochschulen war ursprünglich für
            den Wissenstransfer aus der Praxis sowie die Deckung von
            Lehrbedarfen, die nur unregelmäßig benötigt werden, gedacht. Der
            “typische Lehrauftrag” war zum Beispiel, wenn ein festangestellter
            Musiker aus einem Orchester nebenberuflich sein Wissen an den
            Musikhochschulen vermittelt oder wenn sogenannte “Orchideenfächer”,
            also Fächer, die nur sehr wenig Lehrumfang benötigen, mit dem
            Einsatz von Lehrbeauftragten abgedeckt werden können. Ausdrücklich
            war die Idee, einen vorübergehenden Lehrbedarf zu decken.
            <br /><br /><b>Der Lehrauftrag heute</b><br />In den letzten dreißig
            Jahren haben sich viele Studieninhalte verändert: die Hochschulen
            sind nicht mehr nur eine “Orchesterakademie”, in der man auf eine
            Orchesterstelle vorbereitet wird, sondern es gibt mittlerweile sehr
            viele Studierende in den Bereichen des Lehramts, des Jazz und der
            Instrumental- und Gesangspädagogik.<br />Leider wurden auch diese
            Lehrbedarfe mit den “günstigeren” Lehrbeauftragten gedeckt, obwohl
            die Lehrenden oftmals keine Anstellung haben, sondern selbständig
            und hauptberuflich in der Lehre tätig sind. In einer
            <a
              href="https://faire-lehre.de/files/festanstellung.pdf"
              target="_blank"
              >aktuellen Umfrage</a
            >
            geben zwei Drittel der Lehrbeauftragten an, keine Festanstellung zu
            haben. Für ein Drittel ist der Lehrauftrag die Haupteinnahmequelle.
            Dies widerspricht der Grundidee, dass der Lehrauftrag nur ein
            Nebenberuf ist. Mehr als die Hälfte sind seit mehr als 10 Jahren im
            Lehrauftrag tätig, was zeigt, dass es hier nicht um eine
            vorübergehende Deckung des Lehrbedarfs geht, sondern die Lehre
            dauerhaft an Lehrbeauftragte ausgegliedert wurde. 50 % geben an, in
            der grundständigen Lehre wie Klavier, Theorie und Korrepetition
            tätig zu sein.<br /><br /><b>Für eine faire Lehre braucht es:</b
            ><br />Dauerstellen für Daueraufgaben: der Anteil der
            Lehrbeauftragten muss auf 25% reduziert werden und dabei zunächst
            bestehende Lehraufträge in sozialversicherungspflichtige
            Anstellungen überführt werden. Dieser Anteil erlaubt den Hochschulen
            einerseits auf schwankende Bedarfe zu reagieren und garantiert
            andererseits den Lehrenden eine soziale Absicherung. Hierfür braucht
            es an der Hochschule für Musik Dresden ca. 30 und an der Hochschule
            für Musik und Theater Leipzig ca. 60 Mittelbaustellen, die als
            Teilzeitstellen ausgeschrieben werden sollten, damit möglichst viele
            langjährige Lehrbeauftragte eine Perspektive bekommen.<br /><br /><b
              >Angemessene Vergütung - jetzt und in Zukunft</b
            ><br />Die Vergütung der Lehrbeauftragten muss in Honorarrichtlinien
            formuliert werden, die dem Risiko der Selbstständigkeit tatsächlich
            Rechnung tragen. Die für die Hochschulen notwendige Flexibilität
            darf nicht auf Kosten der Lehrbeauftragten kalkuliert werden. Dazu
            muss der Etat der Hochschulen dauerhaft erhöht werden. Dieser Etat
            muss an die Entwicklung der Tariferhöhungen im öffentlichen Dienst
            gekoppelt werden, um einen Inflationsausgleich in Zukunft abfangen
            zu können und die Honorarentwicklung der Entlohnung der im
            öffentlichen Dienst Beschäftigten anzugleichen. Falls es zu einem
            Aufwuchs der Mittelbaustellen in den Hochschulen kommen sollte, ist
            das jetzige Budget für eine angemessene Vergütung zunächst
            ausreichend. Es Bedarf dann eine Kopplung an die Tarifrunden des
            Tarifvertrags der Länder.<br /><br /><b>Rechtliche Sicherheit</b
            ><br />Der Lehrauftrag kann in Sachsen neben einem privatrechtlichen
            Vertrag auch als Verwaltungsakt ausgestaltet werden. Im Rahmen der
            Novellierung des Hochschulfreiheitsgesetzes braucht es ein klares
            Bekenntnis zum privatrechtlichen Vertrag, um dem Aushebeln der
            rechtlichen Möglichkeiten einen Riegel vorzuschieben. Durch den
            Verwaltungsakt greifen Schutzmechanismen, die in solchen
            Rechtsgeschäften üblich sind, nicht.
          </span>
        </div>

        <hr />

        <div style="padding-bottom: 20px">
          <span
            class=""
            style="
              font-size: 1.2rem;

              font-weight: 700;
              font-variant-ligatures: normal;
              font-variant-east-asian: normal;
              font-variant-position: normal;
              vertical-align: baseline;
              white-space: pre-wrap;
              padding-right: 20px;
            "
            >Beitrag "Sebastian Haas über die Situation von Lehrbeauftragten an
            Musikhochschulen" von Marie König, deutschlandfunk"</span
          >
          <br />
          <a
            href="https://www.deutschlandfunk.de/sebastian-haas-ueber-die-situation-von-lehrbeauftragten-an-musikhochschulen-dlf-a9c8c128-100.html"
            target="_blank"
            ><MDBBtn color="primary" size="sm" style="background-color: #00b74a"
              >Link zum Beitrag
            </MDBBtn></a
          >
          <br />

          <span
            class=""
            style="
              font-size: 9pt;

              font-variant-ligatures: normal;
              font-variant-east-asian: normal;
              font-variant-position: normal;
              vertical-align: baseline;
              white-space: pre-wrap;
            "
            >31.10.2022</span
          >
          <br />
        </div>

        <hr />
        <div style="padding-bottom: 40px">
          <div
            class=""
            style="
              word-wrap: break-word;
              -webkit-nbsp-mode: space;
              line-break: after-white-space;
            "
          >
            <font size="2" class=""><br class="" /></font>
            <div class="">
              <span
                id="docs-internal-guid-a3ccb125-7fff-a902-b6bd-456964913e26"
                class=""
                ><font size="2" class=""
                  ><div
                    class=""
                    style="
                      line-height: 1.38;
                      text-align: justify;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      id="docs-internal-guid-3750bcd5-7fff-79ca-9cf2-e999c99afb75"
                      class=""
                      style="text-align: start"
                      ><div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1.2rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            padding-right: 20px;
                          "
                          >PRESSEMITTEILUNG</span
                        >
                        <a
                          href="/files/Pressemitteilung_20221031.pdf"
                          target="_blank"
                          ><MDBBtn
                            color="primary"
                            size="sm"
                            style="background-color: #00b74a"
                            >Download
                          </MDBBtn></a
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 9pt;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >31.10.2022</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1.2rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >“Lehrstühle statt leere Stühle” Demonstration am
                          21.11.22 vor dem Sächsischen Landtag</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >+++ Die Kampagne “Faire Lehre” fordert mehr
                          Dauerstellen und eine faire Bezahlung +++</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Dresden</span
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                        >
                          - In der Aktion “</span
                        ><a
                          href="http://www.faire-lehre.de/"
                          class=""
                          style="text-decoration: none"
                          ><span
                            class=""
                            style="
                              font-size: 1rem;

                              color: rgb(17, 85, 204);
                              font-variant-ligatures: normal;
                              font-variant-east-asian: normal;
                              font-variant-position: normal;
                              text-decoration: underline;
                              text-decoration-skip-ink: none;
                              vertical-align: baseline;
                              white-space: pre-wrap;
                            "
                            >Faire-Lehre</span
                          ></a
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >” unter dem Hashtag #gesichtzeigen setzen sich
                          derzeit rund 100 Studierende, Lehrende,
                          Professor*innen und Musiker*innen mit ihrem Statement
                          für eine faire Bezahlung und mehr Stellen an den
                          sächsischen Musikhochschulen ein.&nbsp;</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Mit der Kundgebung am <b>Montag, 21.11.22</b> um
                          “fünf vor zwölf” (11:55 Uhr) zeigen die
                          Teilnehmer*innen mit über 100 leeren Stühlen vor dem
                          Sächsischen Landtag den dringenden Handlungsbedarf.
                          Der Entwurf des Doppelhaushalts 23/24 sieht keinerlei
                          Verbesserungen in der Lehre an Musikhochschulen vor.
                          Bei der Aktion unter dem Motto “Lehrstühle statt leere
                          Stühle - für eine faire Lehre an Musikhochschulen in
                          Sachsen” wird daher ein Forderungspapier an Vertreter
                          des Sächsische Staatsministerium für Wissenschaft,
                          Kultur und Tourismus und der Koalitionsparteien CDU,
                          Grüne und SPD übergeben.<br />
                          <br />
                        </span>
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Grund für die Aktion ist eine jahrzehntelange
                          Entwicklung an deutschen Musikhochschulen, in deren
                          Folge hochqualifizierte Pädagogen als “selbständige”
                          Lehrbeauftragte bis zu 50% der Lehre an den
                          Musikhochschulen tragen. Gleichzeitig wird ihnen
                          jegliche Mitgestaltungsmöglichkeit praktisch versagt.
                          Die finanzielle Honorierung ist zudem unzureichend,
                          eine Anstellung nur selten möglich. Vielen droht die
                          Altersarmut.
                        </span>
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Die Corona-Pandemie hat deutlich gemacht, wie fragil
                          die finanzielle Situation von Kulturschaffenden ist.
                          Wenn Lehrende an staatlichen Hochschulen tätig sind,
                          muss eine auskömmliche Entlohnung verbindlich
                          sein.&nbsp;</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Die Initiator*innen fordern von der Politik zum einen
                          eine grundlegende Reform der Lehrstrukur mit mehr
                          Stellen im Mittelbau, um langjährige, in der
                          grundständigen Lehre tätige Lehrbeauftragte in ein
                          Anstellungsverhältnis zu bringen. Zum anderen braucht
                          es einen geeigneten rechtlichen Rahmen sowie eine
                          angemessene Bezahlung, um dem unternehmerischen Risiko
                          der übrigen Lehrbeauftragten als Selbstständige
                          Rechnung zu tragen.&nbsp;

                          <br /><br />Die Kundgebung wird unterstützt von:
                          <b
                            ><br />KSS - Konferenz Sächsischer Studierender
                            <br />Unisono - deutsche Musik- und
                            Orchestervereinigung <br />GEW Sachsen -
                            Gewerkschaft für Erziehung und Wissenschaft
                            <br />Jazzverband Sachsen e.V. <br />BKLM -
                            Bundeskonferenz der Lehrbeauftragten an
                            Musikhochschulen
                          </b>
                        </span>
                      </div>
                      <br class="" />

                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            text-decoration: underline;
                            text-decoration-skip-ink: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Kontakt</span
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >:&nbsp;</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Sebastian Haas</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Mitinitiator der Kampagne “Faire-Lehre”</span
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          ><br class="" /></span
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Lehrbeauftragten-Vertretung der HfM Dresden</span
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          ><br class="" /></span
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Sprecher der Bundeskonferenz der Lehrbeauftragten,
                          Vorstandsmitglied der Deutschen Orchestervereinigung
                          e.V., Vorsitzender des Jazzverband Sachsen e.V.</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <a
                          href="mailto:info@faire-lehre.de"
                          class=""
                          style="text-decoration: none"
                          ><span
                            class=""
                            style="
                              font-size: 1rem;

                              color: rgb(17, 85, 204);
                              font-variant-ligatures: normal;
                              font-variant-east-asian: normal;
                              font-variant-position: normal;
                              text-decoration: underline;
                              text-decoration-skip-ink: none;
                              vertical-align: baseline;
                              white-space: pre-wrap;
                            "
                            >info@faire-lehre.de</span
                          ><span
                            class=""
                            style="
                              font-size: 1rem;

                              color: rgb(0, 0, 0);
                              font-variant-ligatures: normal;
                              font-variant-east-asian: normal;
                              font-variant-position: normal;
                              vertical-align: baseline;
                              white-space: pre-wrap;
                            "
                            ><span
                              class="Apple-tab-span"
                              style="white-space: pre"
                            >
                            </span></span
                        ></a>
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >0160 94676334</span
                        >
                      </div>
                    </span>
                  </div></font
                ></span
              >
            </div>
          </div>
        </div>

        <hr />
        <div style="padding-bottom: 40px">
          <span
            class=""
            style="
              font-size: 1.2rem;

              font-weight: 700;
              font-variant-ligatures: normal;
              font-variant-east-asian: normal;
              font-variant-position: normal;
              vertical-align: baseline;
              white-space: pre-wrap;
              padding-right: 20px;
            "
            >Presse: Zeitung "Neues Deutschland"</span
          >
          <a href="/files/Presse_20221004.pdf" target="_blank"
            ><MDBBtn color="primary" size="sm" style="background-color: #00b74a"
              >Download
            </MDBBtn></a
          >
          <br />
          <br />
          <span
            class=""
            style="
              font-size: 9pt;

              font-variant-ligatures: normal;
              font-variant-east-asian: normal;
              font-variant-position: normal;
              vertical-align: baseline;
              white-space: pre-wrap;
            "
            >04.10.2022</span
          >
          <br />
          <img
            :src="require('@/assets/img/presse_20221004.png')"
            style="width: 100%"
          />
        </div>
        <hr />

        <div style="padding-bottom: 20px">
          <span
            class=""
            style="
              font-size: 1.2rem;

              font-weight: 700;
              font-variant-ligatures: normal;
              font-variant-east-asian: normal;
              font-variant-position: normal;
              vertical-align: baseline;
              white-space: pre-wrap;
              padding-right: 20px;
            "
            >Beitrag "Von Musik leben: Kampagne fordert faire Bezahlung an
            sächsischen Musikhochschulen" von Uta Burkhardt, MDR KULTUR"</span
          >
          <br />
          <a
            href="https://www.mdr.de/kultur/musik/musikhochschule-dresden-leipzig-unfaire-bezahlung-100.html"
            target="_blank"
            ><MDBBtn color="primary" size="sm" style="background-color: #00b74a"
              >Link zum Beitrag
            </MDBBtn></a
          >
          <br />
          <br />
          <span
            class=""
            style="
              font-size: 9pt;

              font-variant-ligatures: normal;
              font-variant-east-asian: normal;
              font-variant-position: normal;
              vertical-align: baseline;
              white-space: pre-wrap;
            "
            >29.09.2022</span
          >
          <br />
          <figure>
            <img
              :src="
                require('@/assets/img/musik-hochschule-dresden100-resimage_v-variantBig24x9_w-2048.webp')
              "
              style="width: 70%"
            />
            <figcaption>Bildrechte: dpa</figcaption>
          </figure>
        </div>

        <hr />
        <div style="padding-bottom: 40px">
          <div
            class=""
            style="
              word-wrap: break-word;
              -webkit-nbsp-mode: space;
              line-break: after-white-space;
            "
          >
            <font size="2" class=""><br class="" /></font>
            <div class="">
              <span
                id="docs-internal-guid-a3ccb125-7fff-a902-b6bd-456964913e26"
                class=""
                ><font size="2" class=""
                  ><div
                    class=""
                    style="
                      line-height: 1.38;
                      text-align: justify;
                      margin-top: 0pt;
                      margin-bottom: 0pt;
                    "
                  >
                    <span
                      id="docs-internal-guid-3750bcd5-7fff-79ca-9cf2-e999c99afb75"
                      class=""
                      style="text-align: start"
                      ><div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1.2rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                            padding-right: 20px;
                          "
                          >PRESSEMITTEILUNG</span
                        >
                        <a
                          href="/files/Pressemitteilung_20220712.pdf"
                          target="_blank"
                          ><MDBBtn
                            color="primary"
                            size="sm"
                            style="background-color: #00b74a"
                            >Download
                          </MDBBtn></a
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 9pt;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >12.07.2022</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1.2rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Prominente Musiker*innen, Lehrende und Studierende
                          stehen gemeinsam für eine Verbesserung an sächsischen
                          Musikhochschulen: “Längst überfällig”&nbsp;</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >+++ Die Kampagne “Faire Lehre” fordert mehr
                          Dauerstellen und eine faire Bezahlung +++</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Dresden / Leipzig</span
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                        >
                          - In der Aktion “</span
                        ><a
                          href="http://www.faire-lehre.de/"
                          class=""
                          style="text-decoration: none"
                          ><span
                            class=""
                            style="
                              font-size: 1rem;

                              color: rgb(17, 85, 204);
                              font-variant-ligatures: normal;
                              font-variant-east-asian: normal;
                              font-variant-position: normal;
                              text-decoration: underline;
                              text-decoration-skip-ink: none;
                              vertical-align: baseline;
                              white-space: pre-wrap;
                            "
                            >Faire-Lehre</span
                          ></a
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >” unter dem Hashtag #gesichtzeigen setzen sich
                          derzeit rund 100 Studierende, Lehrende,
                          Professor*innen und Musiker*innen mit ihrem Statement
                          für eine faire Bezahlung und mehr Stellen an den
                          sächsischen Musikhochschulen ein.&nbsp;</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Grund für die Aktion ist eine jahrzehntelange
                          Entwicklung an deutschen Musikhochschulen, in deren
                          Folge hochqualifizierte Pädagogen als selbständige
                          "Lehrbeauftragte" bis zu 50% der Lehre an den
                          Musikhochschulen tragen. Gleichzeitig wird ihnen
                          jegliche Mitgestaltungsmöglichkeit praktisch versagt.
                          Die finanzielle Honorierung ist zudem unzureichend,
                          eine Anstellung nur selten möglich. Vielen droht die
                          Altersarmut.&nbsp;</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Die Corona-Pandemie hat deutlich gemacht, wie fragil
                          die finanzielle Situation von Kulturschaffenden ist.
                          Wenn Lehrende an staatlichen Hochschulen tätig sind,
                          muss eine auskömmliche Entlohnung verbindlich
                          sein.&nbsp;</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Die Initiator*innen fordern von der Politik zum einen
                          eine grundlegende Reform der Lehrstrukur mit mehr
                          Stellen im Mittelbau, um langjährige, in der
                          grundständigen Lehre tätige Lehrbeauftragte in ein
                          Anstellungsverhältnis zu bringen. Zum anderen braucht
                          es einen geeigneten rechtlichen Rahmen sowie eine
                          angemessene Bezahlung, um dem unternehmerischen Risiko
                          der übrigen Lehrbeauftragten als Selbstständige
                          Rechnung zu tragen.&nbsp;</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Im Anhang finden Sie Zitate von Christian Thielemann,
                          Till Brönner und andere . Weitere Statements und Infos
                          unter: </span
                        ><a
                          href="http://www.faire-lehre.de/"
                          class=""
                          style="text-decoration: none"
                          ><span
                            class=""
                            style="
                              font-size: 1rem;

                              color: rgb(17, 85, 204);
                              font-variant-ligatures: normal;
                              font-variant-east-asian: normal;
                              font-variant-position: normal;
                              text-decoration: underline;
                              text-decoration-skip-ink: none;
                              vertical-align: baseline;
                              white-space: pre-wrap;
                            "
                            >www.faire-lehre.de</span
                          ></a
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >&nbsp;</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Die Kampagne “Faire-Lehre” wird von Lehrbeauftragten
                          aus Dresden und Leipzig sowie den Verbänden </span
                        ><a
                          href="https://www.uni-sono.org/"
                          target="_blank"
                          class=""
                          style="text-decoration: none"
                          ><span
                            class=""
                            style="
                              font-size: 1rem;

                              color: rgb(17, 85, 204);
                              font-variant-ligatures: normal;
                              font-variant-east-asian: normal;
                              font-variant-position: normal;
                              text-decoration: underline;
                              text-decoration-skip-ink: none;
                              vertical-align: baseline;
                              white-space: pre-wrap;
                            "
                            >Deutsche Orchestervereinigung (DOV)</span
                          ></a
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >, dem </span
                        ><a
                          href="http://www.jazzverband-sachsen.de/"
                          target="_blank"
                          class=""
                          style="text-decoration: none"
                          ><span
                            class=""
                            style="
                              font-size: 1rem;

                              color: rgb(17, 85, 204);
                              font-variant-ligatures: normal;
                              font-variant-east-asian: normal;
                              font-variant-position: normal;
                              text-decoration: underline;
                              text-decoration-skip-ink: none;
                              vertical-align: baseline;
                              white-space: pre-wrap;
                            "
                            >Jazzverband Sachsen e.V.</span
                          ></a
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                        >
                          und der </span
                        ><a
                          href="https://www.bklm.org/"
                          target="_blank"
                          class=""
                          style="text-decoration: none"
                          ><span
                            class=""
                            style="
                              font-size: 1rem;

                              color: rgb(17, 85, 204);
                              font-variant-ligatures: normal;
                              font-variant-east-asian: normal;
                              font-variant-position: normal;
                              text-decoration: underline;
                              text-decoration-skip-ink: none;
                              vertical-align: baseline;
                              white-space: pre-wrap;
                            "
                            >Bundeskonferenz der Lehrbeauftragten (BKLM</span
                          ></a
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >) unterstützt.</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            text-decoration: underline;
                            text-decoration-skip-ink: none;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Kontakt</span
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >:&nbsp;</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Sebastian Haas</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Mitinitiator der Kampagne “Faire-Lehre”</span
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          ><br class="" /></span
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Lehrbeauftragten-Vertretung der HfM Dresden</span
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          ><br class="" /></span
                        ><span
                          class=""
                          style="
                            font-size: 1rem;

                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Sprecher der Bundeskonferenz der Lehrbeauftragten,
                          Vorstandsmitglied der Deutschen Orchestervereinigung
                          e.V., Vorsitzender des Jazzverband Sachsen e.V.</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <a
                          href="mailto:info@faire-lehre.de"
                          class=""
                          style="text-decoration: none"
                          ><span
                            class=""
                            style="
                              font-size: 1rem;

                              color: rgb(17, 85, 204);
                              font-variant-ligatures: normal;
                              font-variant-east-asian: normal;
                              font-variant-position: normal;
                              text-decoration: underline;
                              text-decoration-skip-ink: none;
                              vertical-align: baseline;
                              white-space: pre-wrap;
                            "
                            >info@faire-lehre.de</span
                          ><span
                            class=""
                            style="
                              font-size: 1rem;

                              color: rgb(0, 0, 0);
                              font-variant-ligatures: normal;
                              font-variant-east-asian: normal;
                              font-variant-position: normal;
                              vertical-align: baseline;
                              white-space: pre-wrap;
                            "
                            ><span
                              class="Apple-tab-span"
                              style="white-space: pre"
                            >
                            </span></span
                        ></a>
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >0160 94676334</span
                        >
                      </div>
                      <br class="" /><br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1.2rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Christian Thielemann, Till Brönner, Günter “Baby”
                          Sommer und viele andere unterstützen die Kampagne
                          “Faire Lehre”:</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Längst überfällig!&nbsp;</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            color: rgb(5, 5, 5);
                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >"Ohne ein deutliches Umdenken an Musikhochschulen mit
                          ihrer Lehr- und Honorarstruktur werden viele
                          hochqualifizierte Musiker und Lehrer im Alter in Armut
                          leben müssen. Das ist unverantwortlich! Jeder Akteur
                          in einem staatlichen Kultur- und Hochschulbetrieb
                          sollte daher angemessen bezahlt werden und wo es die
                          Aufgaben erfordern, ein sicheres Anstellungsverhältnis
                          erhalten."</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            color: rgb(5, 5, 5);
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Christian Thielemann - Chefdirigent der Sächsischen
                          Staatskapelle</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Unwürdig für Deutschland!</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >"Die Zahl der Lehrbeauftragten an unseren
                          Einrichtungen ist der Beweis für den Bedarf
                          hochqualifizierter Arbeit auf Professorenniveau. Das
                          mindeste sollte dafür eine adäquate und planbare
                          Entlohnung sein!“</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Till Brönner - Professor, Jazztrompeter</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Unseriös!</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >"Ich unterstütze die Kampagne, weil sie mich
                          persönlich betrifft und ich seit vielen Jahren schon
                          gegen die unseriöse Fassung dieser Lehraufträge
                          bin"</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Günter “Baby” Sommer - Lehrbeauftragter,
                          Schlagzeuger</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Die Inititiative #gesichtzeigen hat die überzeugte
                          Unterstützung des Sächsischen Musikrats&nbsp;</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >"Das an die Künstlersozialkasse gemeldete
                          Durchschnitts-Jahreseinkommen in 2021 lag im Bereich
                          Musik bei 13000 €. Über strukturell entstehende
                          Altersarmut kann angesichts dieser Zahl niemand
                          hinwegsehen. Ein hoher Anteil freiberuflicher
                          Musiker*innen ist partiell oder umfänglich in der
                          Lehre an Musikschulen und Musikhochschulen tätig. Die
                          Initiative #gesichtzeigen hat deshalt die überzeugte
                          Unterstützung des Sächsischen Musikrats”</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Prof. Milko Kersten - Präsident des Sächsischen
                          Musikrat e.V.</span
                        >
                      </div>
                      <br class="" />
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            font-weight: 700;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Ohne Lehrbeauftragte können wir nicht
                          studieren&nbsp;</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            color: rgb(5, 5, 5);
                            font-style: italic;
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >"Wir als Studierende der Hochschule für Musik und
                          Theater Leipzig sind auf Lehrbeauftragte angewiesen.
                          Ohne ihren unermüdlichen Einsatz für uns würden wir
                          unsere Unterrichte und Veranstaltungen nicht
                          wahrnehmen können. Wir finden es bestürzend, in in
                          welch prekären Arbeitsverhältnissen sie beschäftigt
                          sind: befristete Verträge und deutliche niedrigere
                          Entlohnung für gleiche Arbeit im Gegensatz zu den
                          Festangestellten. Dass das System Hochschulbildung
                          ohne sie nicht funktionieren würde, steht außer Frage.
                          Deswegen stellen wir uns hinter die Lehrbeauftragten
                          und ihren Forderungen und sagen:
                          #FAIRstattPREKÄR."</span
                        >
                      </div>
                      <div
                        class=""
                        style="
                          line-height: 1.38;
                          text-align: justify;
                          margin-top: 0pt;
                          margin-bottom: 0pt;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 1rem;

                            color: rgb(5, 5, 5);
                            font-variant-ligatures: normal;
                            font-variant-east-asian: normal;
                            font-variant-position: normal;
                            vertical-align: baseline;
                            white-space: pre-wrap;
                          "
                          >Fachschaftsrat Musikpädagogik der HMT Leipzig</span
                        >
                      </div></span
                    >
                  </div></font
                ></span
              >
            </div>
          </div>
        </div>
      </MDBRow>
    </MDBContainer>

    <vue-final-modal
      v-if="showModal"
      v-model="showModal"
      classes="modal-container"
      :content-class="modalStyle"
      :content-style="modalStyleBig"
    >
      <button class="modal__close" @click="showModal = false">
        <div><MDBIcon style="color: #696969" icon="times" size="2x" /></div>
      </button>
      <div class="modal__title">
        <span>{{ detail.heading }}</span>
        <span style="font-weight: 200; font-size: 15px"
          ><small>_____{{ detail.vorname }} {{ detail.nachname }}</small></span
        >
      </div>
      <div class="modal__content">
        <div class="container">
          <MDBRow>
            <MDBCol md="6">
              <div>{{ detail.text }}</div>
            </MDBCol>
            <MDBCol md="6">
              <div>
                <div class="position-relative">
                  <img
                    :src="
                      'https://faire-lehre.de/faire_lehre_file/files/' +
                      detail.image
                    "
                    class="w-100 bg-image"
                    style="border-radius: 2%"
                  />
                  <div
                    style="font-size: 16px"
                    class="rounded-3 px-2 m-2 bg-dark bg-gradient text-white bg-opacity-75 position-absolute bottom-0 end-0"
                  >
                    {{ detail.beruf }}
                  </div>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </div>
    </vue-final-modal>

    <vue-final-modal
      v-if="showDemo"
      v-model="showDemo"
      classes="modal-container"
      :content-class="modalStyle"
      :content-style="modalStyleBig"
    >
      <button class="modal__close" @click="showDemo = false">
        <div><MDBIcon style="color: #696969" icon="times" size="2x" /></div>
      </button>
      <div class="modal__title"></div>
      <div class="modal__content">
        <div class="container">
          <MDBRow>
            <img src="@/assets/img/lehrstuehle_statt_leere_stuehle.png" />
          </MDBRow>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import AgilSlider from "@/components/AgilSlider.vue";
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBIcon,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardText,
} from "mdb-vue-ui-kit";
import StatementDataService from "../services/StatementDataService";
import { VueFinalModal } from "vue-final-modal";

export default {
  name: "HomeView",
  components: {
    AgilSlider,
    MDBCol,
    MDBRow,
    MDBContainer,
    MDBIcon,
    MDBBtn,
    VueFinalModal,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardText,
  },
  data() {
    return {
      msg: "",
      statements: {},
      showDemo: false,
      showModal: false,
      modalStyle: "modal-content w-75",
      detailStyle: "col-md-6",
      modalStyleBig: "",
      detail: {},
      mounted: false,
      numberOfColumns: 5,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  methods: {
    showDetails(statement) {
      //console.log(statement.text);
      this.detail = statement;
      this.showModal = true;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 340) {
        this.numberOfColumns = 1;
        this.modalStyle = "modal-content w-100";
        this.detailStyle = "col-md-6";
        this.modalStyleBig = "width: 100% !important;";
      }
      if (this.window.width >= 340) {
        this.numberOfColumns = 2;
        this.modalStyle = "modal-content w-100";
        this.detailStyle = "w-50 col-md-6";
        this.modalStyleBig = "width: 100% !important;";
      }
      if (this.window.width >= 768) {
        this.numberOfColumns = 3;
        this.modalStyle = "modal-content w-75";
        this.detailStyle = "col-md-6";
        this.modalStyleBig = "width: 75% !important;";
      }
      if (this.window.width >= 992) {
        this.numberOfColumns = 4;
        this.modalStyle = "modal-content w-75";
        this.detailStyle = "col-md-6";
        this.modalStyleBig = "width: 75% !important;";
      }
      if (this.window.width >= 1200) {
        this.numberOfColumns = 5;
        this.modalStyle = "modal-content";
        this.modalStyleBig = "width: 60% !important;";
        this.detailStyle = "col-md-6";
      }
    },

    async retrieveStatements() {
      await StatementDataService.getAllPublished()
        .then((response) => {
          this.statements = response.data;
          this.mounted = true;
          //console.log("HIER", response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    window.scrollTo(0, 0);

    /*
    setTimeout(() => {
      this.showDemo = true;
    }, 1000);

  */
  },
  async created() {
    await this.retrieveStatements();
    this.modalStyle = "modal-content w-50";
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  computed: {
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.numberOfColumns}, minmax(100px, 1fr))`,
      };
    },
  },
};
</script>
<style>
#header_start {
  font-family: "Oswald-Regular";
  font-size: 45px;
  margin-top: -30px;
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
  #header_start {
    font-size: 60px;
  }
}

#header_logo {
  width: 70%;
  margin-top: 40px;
}

@media (min-width: 576px) {
  #header_logo {
    width: 28%;
    margin-top: 40px;
  }
}

#sub-header_start {
  font-family: "Caveat-SemiBold";
  color: rgba(255, 255, 255, 0.9);
}

.card-list {
  display: grid;
  grid-gap: 5px;
}

.card-item {
  background-color: rgb(173, 223, 171);
  padding: 0;
}

.img-thumbnail {
  padding: 0;
  background-color: #fff;
  border: 0;
  border-radius: 0;
}

.fixed-top {
  z-index: 900;
}
</style>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 25px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 20px;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

button {
  background-color: transparent;
  background-image: none;
  border: 0 solid #e2e8f0;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
