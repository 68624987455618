<template>
  <!-- Footer -->

  <MDBFooter :text="['center', 'lg-start', 'muted']">
    <!-- Section: Social media -->

    <section
      class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
    >
      <!-- Left -->

      <div class="me-5 d-none d-lg-block"></div>

      <!-- Left -->
      <!-- Right -->

      <div>
        <span class="me-3">Vernetze dich mit uns:</span>
        <a
          href="https://www.facebook.com/fairelehre/"
          target="_blank"
          class="me-4 text-reset"
        >
          <MDBIcon iconStyle="fab" icon="facebook-f"></MDBIcon>
        </a>
        <a
          href="https://www.instagram.com/fairelehre/"
          target="_blank"
          class="me-4 text-reset"
        >
          <MDBIcon iconStyle="fab" icon="instagram"></MDBIcon>
        </a>
        <!--
        <a href="" class="me-4 text-reset">
          <MDBIcon iconStyle="fab" icon="twitter"></MDBIcon>
        </a>
        <a href="" class="me-4 text-reset">
          <MDBIcon iconStyle="fab" icon="google"></MDBIcon>
        </a>
       
        <a href="" class="me-4 text-reset">
          <MDBIcon iconStyle="fab" icon="linkedin"></MDBIcon>
        </a>
        <a href="" class="me-4 text-reset">
          <MDBIcon iconStyle="fab" icon="github"></MDBIcon>
        </a>
        -->
      </div>

      <!-- Right -->
    </section>

    <!-- Section: Social media -->

    <!-- Section: Links  -->
    <section class="pt-1">
      <MDBContainer class="text-center text-md-start mt-5">
        <!-- Grid row -->
        <MDBRow class="mt-3">
          <!-- Grid column -->
          <MDBCol md="3" lg="4" xl="3" class="mx-auto mb-4">
            <!-- Content -->
            <h6 class="text-uppercase fw-bold mb-4">
              <i class="fas fa-ellipsis-v me-3"></i>#gesichtzeigen
            </h6>
            <p>
              Für eine faire Vergütung und Dauerstellen für Daueraufgaben an den
              sächsischen Musikhochschulen
            </p>
          </MDBCol>
          <!-- Grid column -->

          <!-- Grid column -->
          <MDBCol md="2" lg="2" xl="2" class="mx-auto mb-4">
            <!-- Links -->
            <a href="/mitmachen">
              <MDBBtn outline="success">Mitmachen</MDBBtn>
            </a>
          </MDBCol>
          <!-- Grid column -->
          <!-- Grid column -->
          <MDBCol md="3" lg="2" xl="2" class="mx-auto mb-4">
            <!-- Links -->

            <p>
              <a href="/impressum" class="text-reset">Impressum</a>
            </p>
            <p>
              <a href="/datenschutz" class="text-reset">Datenschutz</a>
            </p>
          </MDBCol>
          <!-- Grid column -->
          <!-- Grid column -->
          <MDBCol md="4" lg="3" xl="3" class="mx-auto mb-md-0 mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">Kontakt</h6>

            <p>
              <i class="fas fa-envelope me-3"></i>
              info@faire-lehre.de
            </p>

            <p><MDBIcon icon="phone" class="me-3" /> +49 (0) 160 94 67 63 34</p>
          </MDBCol>
          <!-- Grid column -->
        </MDBRow>
        <!-- Grid row -->
      </MDBContainer>
    </section>
    <!-- Section: Links  -->
    <!-- Copyright -->
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05)">
      © {{ year }} Copyright:
      <a class="text-reset fw-bold" href="https://faire-lehre.de"
        >faire-lehre.de</a
      >
    </div>

    <!-- Copyright -->
  </MDBFooter>
  <!-- Footer -->
</template>

<script>
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBIcon,
} from "mdb-vue-ui-kit";
export default {
  components: {
    MDBFooter,
    MDBContainer,
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBIcon,
  },
  data: function () {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
