<template>
  <div>
    <MDBContainer style="margin-top: 80px">
      <MDBRow class="p-1" style="margin-top: 120px" v-if="!auth">
        <MDBCol col="12" class="d-flex justify-content-center">
          <MDBCard class="border border-5 d-flex">
            <MDBCardBody>
              <MDBCardTitle>Admin Panel</MDBCardTitle>
              <MDBCardText class="">
                <MDBInput
                  v-model="passwd"
                  id="passwd"
                  class="p-1 m-4"
                  label="Passwort"
                  type="password"
                />
              </MDBCardText>
              <div v-if="wrong_passwd" class="text-danger p-1 mb-3">
                Falsches Passwort
              </div>
              <MDBBtn
                color="primary"
                id="loginBtn"
                size="lg"
                class="me-2"
                style="background-color: #00b74a"
                @click="login()"
                >Anmelden</MDBBtn
              >
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow style="margin-top: 50px" v-if="auth">
        <div class="col-4 ms-5 mb-4 p-2 border border-secondary rounded-4">
          <MDBCheckbox
            label="Filter: nur unveröffentlichte Einträge"
            v-model="not_published"
          />
        </div>
        <span class="col-4 ms-2 mb-4 p-2"
          >{{ filteredStatements().length }}/{{ this.statements.length }}</span
        >
        <div v-if="mounted" :style="gridStyle" class="card-list">
          <div
            v-for="statement in filteredStatements()"
            :key="statement.id"
            class="card-item"
            @click="showDetails(statement)"
          >
            <div
              class="bg-image ripple hover-zoom"
              data-mdb-ripple-color="light"
            >
              <img
                :src="
                  'https://faire-lehre.de/faire_lehre_file/files/' +
                  statement.image
                "
                class="w-100"
              />

              <div
                class="mask"
                style="background-color: rgba(90, 150, 118, 0.4)"
              >
                <div
                  class="d-flex justify-content-center align-items-center h-100"
                >
                  <div class="text-justify">
                    <h5
                      class="text-white mb-0 text-justify"
                      style="text-align: center"
                    >
                      {{ statement.heading }}
                    </h5>

                    <p
                      class="text-white mb-0 text-justify"
                      style="
                        font-weight: 200;
                        font-size: 14px;
                        text-align: center;
                      "
                    >
                      <strong
                        >{{ statement.vorname }} {{ statement.nachname }}
                      </strong>
                    </p>
                  </div>
                  <div
                    style="font-size: 14px"
                    class="rounded-3 px-2 m-2 bg-dark bg-gradient text-white bg-opacity-75 position-absolute bottom-0 end-0"
                  >
                    {{ statement.beruf }}
                  </div>
                </div>
              </div>
              <div class="hover-overlay">
                <div
                  class="mask"
                  style="background-color: rgba(251, 251, 251, 0.2)"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </MDBRow>
    </MDBContainer>

    <vue-final-modal
      v-if="showModal"
      v-model="showModal"
      classes="modal-container"
      :content-class="modalStyle"
      d-flex
      justify-content-center
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <MDBSwitch
              label="Freigeben"
              @change="UpdatePublished(detail)"
              v-model="detail.published"
            />
          </div>
          <div class="col-lg">
            <MDBCheckbox
              @change="UpdateFavorit(detail)"
              label="Favorit"
              v-model="detail.favorit"
            />
          </div>
          <div class="col-lg">
            <MDBBtn
              style="background-color: #f93154"
              @click="deleteStatement(detail)"
              color="danger"
              >Löschen</MDBBtn
            >
          </div>
          <div class="col-lg">
            {{ detail.datum }}
          </div>
        </div>
      </div>

      <button class="modal__close" @click="showModal = false">
        <div><MDBIcon style="color: #696969" icon="times" size="2x" /></div>
      </button>
      <div class="modal__title mt-2">
        <span>{{ detail.heading }}</span>
        <span style="font-weight: 200; font-size: 15px"
          ><small
            >_____{{ detail.vorname }} {{ detail.nachname }} [<a
              :href="'mailto:' + detail.email"
              >{{ detail.email }}</a
            >]</small
          ></span
        >
      </div>
      <div class="modal__content">
        <div class="container">
          <div class="row">
            <div :class="detailStyle">{{ detail.text }}</div>
            <div :class="detailStyle">
              <div class="position-relative">
                <img
                  :src="
                    'https://faire-lehre.de/faire_lehre_file/files/' +
                    detail.image
                  "
                  class="w-100 bg-image"
                  style="border-radius: 2%"
                />
                <div
                  style="font-size: 16px"
                  class="rounded-3 px-2 m-2 bg-dark bg-gradient text-white bg-opacity-75 position-absolute bottom-0 end-0"
                >
                  {{ detail.beruf }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  MDBRow,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBCol,
  MDBSwitch,
  MDBCheckbox,
} from "mdb-vue-ui-kit";
import StatementDataService from "../services/StatementDataService";
import { VueFinalModal } from "vue-final-modal";
import moment from "moment";

export default {
  name: "AdminView",
  components: {
    MDBRow,
    MDBContainer,
    MDBIcon,
    VueFinalModal,
    MDBInput,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBCol,
    MDBSwitch,
    MDBCheckbox,
  },
  data() {
    return {
      msg: "",
      statements: {},
      showModal: false,
      auth: false,
      modalStyle: "modal-content w-50",
      detailStyle: "col-sm-6",
      detail: {},
      passwd: "",
      not_published: true,
      wrong_passwd: false,
      mounted: false,
      numberOfColumns: 6,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  methods: {
    UpdatePublished(statement) {
      const data = {
        id: statement.id,
        published: !statement.published,
      };
      this.updateStatement(data);
      //console.log(!published);
    },

    UpdateFavorit(statement) {
      const data = {
        id: statement.id,
        favorit: !statement.favorit,
      };

      this.updateStatement(data);
    },

    filteredStatements() {
      let result = this.statements;
      if (this.not_published) {
        result = result.filter((c) => c.published === false);
      }
      return result;
    },

    login() {
      console.log(this.passwd);
      this.retrieveStatements(this.passwd);
    },

    showDetails(statement) {
      //console.log(statement.text);
      this.detail = statement;
      this.detail.datum = moment(statement.createdAt).format(
        "DD.MM.YYYY HH:mm"
      );

      this.showModal = true;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 340) {
        this.numberOfColumns = 1;
        this.modalStyle = "modal-content w-100";
        this.detailStyle = "col-sm-6";
      }
      if (this.window.width >= 340) {
        this.numberOfColumns = 2;
        this.modalStyle = "modal-content w-100";
        this.detailStyle = "w-50 col-sm-6";
      }
      if (this.window.width >= 768) {
        this.numberOfColumns = 3;
        this.modalStyle = "modal-content w-50";
        this.detailStyle = "col-sm-6";
      }
      if (this.window.width >= 992) {
        this.numberOfColumns = 4;
        this.modalStyle = "modal-content w-50";
        this.detailStyle = "col-sm-6";
      }
      if (this.window.width >= 1200) {
        this.numberOfColumns = 6;
        this.modalStyle = "modal-content w-50";
        this.detailStyle = "col-sm-6";
      }
    },

    async updateStatement(statement) {
      console.log("Statement:", statement);
      await StatementDataService.update(statement.id, statement)
        .then((response) => {
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async deleteStatement(statement) {
      await StatementDataService.delete(statement.id)
        .then((response) => {
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
      this.statements = this.statements.filter((c) => c.id !== statement.id);
      this.showModal = false;
    },

    async retrieveStatements(passwd) {
      const data = {
        password: passwd,
      };
      await StatementDataService.admin(data)
        .then((response) => {
          if (response.data != "wrong") {
            this.statements = response.data;

            this.mounted = true;
            this.auth = true;
          } else {
            this.wrong_passwd = true;
          }

          console.log("HIER", response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  async mounted() {
    window.scrollTo(0, 0);
    var input = document.getElementById("passwd");
    input.addEventListener("keyup", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("loginBtn").click();
      }
    });
  },
  async created() {
    this.modalStyle = "modal-content w-50";
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  computed: {
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.numberOfColumns}, minmax(100px, 1fr))`,
      };
    },
  },
};
</script>
<style>
.form-switch .form-check-input::after {
  background-color: #c0c0c0;
}

.form-check {
  width: 100%;
}

.form-switch .form-check-input:checked[type="checkbox"]::after {
  background-color: #00b74a;
}

.form-switch .form-check-input:checked[type="checkbox"]::before {
  background-color: #00b74a;
}

.form-switch .form-check-input[type="checkbox"]:checked {
  background-image: none;
  background-color: #00b74a;
}

.form-switch .form-check-input:active[type="checkbox"] {
  background-image: none;
  background-color: #00b74a;
}

.form-switch .form-check-input:active[type="checkbox"]::before {
  background-image: none;
  background-color: #00b74a;
}

.card-list {
  display: grid;
  grid-gap: 5px;
}

.card-item {
  background-color: rgb(173, 223, 171);
  padding: 0;
}

.img-thumbnail {
  padding: 0;
  background-color: #fff;
  border: 0;
  border-radius: 0;
}

.fixed-top {
  z-index: 900;
}
</style>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 25px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 20px;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

button {
  background-color: transparent;
  background-image: none;
  border: 0 solid #e2e8f0;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
