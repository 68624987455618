<template>
  <MDBNavbar
    style="padding: 0"
    expand="lg"
    light
    bg="light"
    position="top"
    container
  >
    <MDBNavbarBrand style="padding: 0" @click="collapse1 = false" href="/"
      ><img src="@/assets/img/logo4.png" height="60" alt="" loading="lazy" />
      <div class="align-items-center" id="nav-header">
        Faire Lehre
      </div></MDBNavbarBrand
    >
    <MDBNavbarToggler
      @click="collapse1 = !collapse1"
      target="#navbarSupportedContent"
    ></MDBNavbarToggler>
    <MDBCollapse v-model="collapse1" id="navbarSupportedContent">
      <MDBNavbarNav right class="mb-2 mb-lg-0 align-items-center">
        <MDBNavbarItem @click="collapse1 = !collapse1" to="/" class="me-2"
          >Home</MDBNavbarItem
        >
        <MDBNavbarItem
          @click="collapse1 = !collapse1"
          to="mitmachen"
          class="me-2"
          ><MDBBtn size="md" class="me-2" outline="success">
            Mitmachen
          </MDBBtn></MDBNavbarItem
        >
        <MDBNavbarItem
          to="initiative"
          @click="collapse1 = !collapse1"
          class="me-2"
          >Die Initiative
        </MDBNavbarItem>

        <MDBNavbarItem
          to="wir-sind-dabei"
          @click="collapse1 = !collapse1"
          class="me-2"
        >
          Wir sind dabei!
        </MDBNavbarItem>
        <MDBNavbarItem @click="collapse1 = !collapse1" class="me-1" to="/ueber">
          Über uns</MDBNavbarItem
        >
        <!--
        <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  -->
      </MDBNavbarNav>
    </MDBCollapse>
  </MDBNavbar>
</template>

<script>
import {
  MDBBtn,
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
} from "mdb-vue-ui-kit";
import { ref } from "vue";
export default {
  components: {
    MDBBtn,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
  },
  mounted() {},
  methods: {
    closeMenu() {
      document
        .getElementById("navbarSupportedContent")
        .classList.remove("show");

      const navbar = document.getElementsByClassName("navbar-toggler");
      navbar[0].setAttribute("aria-expanded", "true");
    },
  },
  setup() {
    const collapse1 = ref(false);
    const dropdown1 = ref(false);
    return {
      collapse1,
      dropdown1,
    };
  },
};
</script>

<style>
#nav-header {
  font-family: "Oswald-Regular";
  font-size: 30px;
  color: rgba(60, 95, 89, 0.5);
}
</style>
