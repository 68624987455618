<template>
  <div style="padding-top: 60px">
    <MDBContainer class="mt-lg-5 mt-3">
      <MDBRow>
        <div class="datenschutz">
          <h1>Datenschutzerklärung</h1>

          <p>
            <strong
              >Anbieter und verantwortliche Stelle im Sinne des
              Datenschutzgesetzes</strong
            >
          </p>
          <p>
            <span id="s3-t-firma">Sebastian Haas</span><br /><span
              id="s3-t-strasse"
              >Georgenstrasse 4 </span
            ><br /><span id="s3-t-plz">01097</span>
            <span id="s3-t-ort"> Dresden</span>
          </p>
          <p><strong>Geltungsbereich</strong></p>
          <p>
            Nutzer erhalten mit dieser Datenschutzerklärung Information über die
            Art, den Umfang und Zweck der Erhebung und Verwendung ihrer Daten
            durch den verantwortlichen Anbieter erhoben und verwendet werden.
          </p>
          <p>
            Den rechtlichen Rahmen für den Datenschutz bilden das
            Bundesdatenschutzgesetz (BDSG) und das Telemediengesetz (TMG).
          </p>
          <p><strong>Erfassung allgemeiner Informationen</strong></p>
          <p>
            Mit jedem Zugriff auf dieses Angebot werden durch uns bzw. den
            Webspace-Provider automatisch Informationen erfasst. Diese
            Informationen, auch als Server-Logfiles bezeichnet, sind allgemeiner
            Natur und erlauben keine Rückschlüsse auf Ihre Person.
          </p>
          <p>
            Erfasst werden unter anderem: Name der Webseite, Datei, Datum,
            Datenmenge, Webbrowser und Webbrowser-Version, Betriebssystem, der
            Domainname Ihres Internet-Providers, die sogenannte Referrer-URL
            (jene Seite, von der aus Sie auf unser Angebot zugegriffen haben)
            und die IP-Adresse.
          </p>
          <p>
            Ohne diese Daten wäre es technisch teils nicht möglich, die Inhalte
            der Webseite auszuliefern und darzustellen. Insofern ist die
            Erfassung der Daten zwingend notwendig. Darüber hinaus verwenden wir
            die anonymen Informationen für statistische Zwecke. Sie helfen uns
            bei der Optimierung des Angebots und der Technik. Wir behalten uns
            zudem das Recht vor, die Log-Files bei Verdacht auf eine
            rechtswidrige Nutzung unseres Angebotes nachträglich zu
            kontrollieren.
          </p>
          <p><strong>Umgang mit personenbezogenen Daten</strong></p>
          <p>
            Definition: Als personenbezogene Daten gelten alle Informationen,
            anhand derer eine Person eindeutig identifizierbar ist. Es handelt
            sich somit um Daten, die zu einer Person zurückverfolgt werden
            können.
          </p>
          <p>
            Zu diesen personenbezogenen Daten zählen der Vorname und der Name,
            die Telefonnummer sowie die E-Mail-Adresse. Ebenso als
            personenbezogene Daten gelten Informationen zu Hobbies,
            Mitgliedschaften und Vorlieben sowie Internetseiten, die aufgerufen
            wurden.
          </p>
          <p>
            Diese Daten werden vom Anbieter nur erhoben, genutzt und
            gegebenenfalls weitergegeben, sofern der Gesetzgeber dies
            ausdrücklich erlaubt oder aber der Nutzer in die Erhebung,
            Bearbeitung, Nutzung und Weitergabe der Daten einwilligt.
          </p>

          <p><strong>Kontakt</strong></p>
          <p>
            Wenn Sie uns per E-Mail kontaktieren, speichern wir die von Ihnen
            gemachten Angaben, um Ihre Anfrage beantworten und mögliche
            Anschlussfragen stellen zu können.
          </p>
          <p><strong>Einbindung von Diensten und Inhalten Dritter</strong></p>
          <p>
            Unser Angebot umfasst mitunter Inhalte, Dienste und Leistungen
            anderer Anbieter. Das sind zum Beispiel Karten, die von Google-Maps
            zur Verfügung gestellt werden, Videos von YouTube sowie Grafiken und
            Bilder anderer Webseiten. Damit diese Daten im Browser des Nutzers
            aufgerufen und dargestellt werden können, ist die Übermittlung der
            IP-Adresse zwingend notwendig. Die Anbieter (im Folgenden als
            „Dritt-Anbieter“ bezeichnet) nehmen also die IP-Adresse des
            jeweiligen Nutzers wahr.
          </p>
          <p>
            Auch wenn wir bemüht sind, ausschließlich Dritt-Anbieter zu nutzen,
            welche die IP-Adresse nur benötigen, um Inhalte ausliefern zu
            können, haben wir keinen Einfluss darauf, ob die IP-Adresse
            möglicherweise gespeichert wird. Dieser Vorgang dient in dem Fall
            unter anderem statistischen Zwecken. Sofern wir Kenntnis davon
            haben, dass die IP-Adresse gespeichert wird, weisen wir unsere
            Nutzer darauf hin.
          </p>
          <p><strong>Cookies</strong></p>
          <p>Diese Webseite verwendet keine Cookies.</p>
          <p><strong>Datensparsamkeit</strong></p>
          <p>
            Personenbezogene Daten speichern wir gemäß den Grundsätzen der
            Datenvermeidung und Datensparsamkeit nur so lange, wie es
            erforderlich ist oder vom Gesetzgeber her vorgeschrieben wird
            (gesetzliche Speicherfrist). Entfällt der Zweck der erhobenen
            Informationen oder endet die Speicherfrist, sperren oder löschen wir
            die Daten.
          </p>
          <p>
            <strong
              >Ihre Rechte auf Auskunft, Berichtigung, Sperre, Löschung und
              Widerspruch</strong
            >
          </p>
          <p>
            Sie haben das Recht, auf Antrag unentgeltlich eine Auskunft, über
            die bei uns gespeicherten personenbezogenen Daten, anzufordern
            und/oder eine Berichtigung, Sperrung oder Löschung zu verlangen.
            Ausnahmen: Es handelt sich um die vorgeschriebene Datenspeicherung
            zur Geschäftsabwicklung oder die Daten unterliegen der gesetzlichen
            Aufbewahrungspflicht.
          </p>
          <p>
            Um eine Datensperre jederzeit berücksichtigen zu können, ist es
            erforderlich, die Daten für Kontrollzwecke in einer Sperrdatei
            vorzuhalten. Besteht keine gesetzliche Archivierungspflicht, können
            Sie auch die Löschung der Daten verlangen. Anderenfalls sperren wir
            die Daten, sofern Sie dies wünschen.
          </p>
          <p><strong>Änderung unserer Datenschutzerklärung</strong></p>
          <p>
            Um zu gewährleisten, dass unsere Datenschutzerklärung stets den
            aktuellen gesetzlichen Vorgaben entspricht, behalten wir uns
            jederzeit Änderungen vor. Das gilt auch für den Fall, dass die
            Datenschutzerklärung aufgrund neuer oder überarbeiteter Leistungen,
            zum Beispiel neuer Serviceleistungen, angepasst werden muss. Die
            neue Datenschutzerklärung greift dann bei Ihrem nächsten Besuch auf
            unserem Angebot.
          </p>
          <p></p>
        </div>
      </MDBRow>
    </MDBContainer>
  </div>
</template>

<script>
import { MDBRow, MDBContainer } from "mdb-vue-ui-kit";

export default {
  name: "DatenschutzView",
  components: {
    MDBRow,
    MDBContainer,
  },
  data() {
    return {
      msg: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
