<template>
  <div style="padding-top: 60px">
    <loading opacity="1" :active="isLoading" :is-full-page="fullPage"></loading>
    <MDBContainer class="mt-lg-5 mt-3">
      <h1>Wir sind dabei!</h1>
      <MDBRow>
        <MDBCol sm="12" class="pt-3">
          <div v-if="mounted" :style="gridStyle" class="card-list">
            <div
              v-for="statement in statements"
              :key="statement.id"
              class="card-item"
              @click="showDetails(statement)"
            >
              <div
                class="bg-image ripple hover-zoom"
                data-mdb-ripple-color="light"
              >
                <img
                  :src="
                    'https://faire-lehre.de/faire_lehre_file/files/' +
                    statement.image
                  "
                  class="w-100"
                />

                <div
                  class="mask"
                  style="background-color: rgba(90, 150, 118, 0.4)"
                >
                  <div
                    class="d-flex justify-content-center align-items-center h-100"
                  >
                    <div class="text-justify">
                      <h5
                        class="text-white mb-0 text-justify"
                        style="text-align: center"
                      >
                        {{ statement.heading }}
                      </h5>

                      <p
                        class="text-white mb-0 text-justify"
                        style="
                          font-weight: 200;
                          font-size: 14px;
                          text-align: center;
                        "
                      >
                        <strong
                          >{{ statement.vorname }}
                          {{ statement.nachname }}</strong
                        >
                      </p>
                    </div>
                    <div
                      style="font-size: 14px"
                      class="rounded-3 px-2 m-2 bg-dark bg-gradient text-white bg-opacity-75 position-absolute bottom-0 end-0"
                    >
                      {{ statement.beruf }}
                    </div>
                  </div>
                </div>
                <div class="hover-overlay">
                  <div
                    class="mask"
                    style="background-color: rgba(251, 251, 251, 0.2)"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>

    <vue-final-modal
      v-if="showModal"
      v-model="showModal"
      classes="modal-container"
      :content-class="modalStyle"
      :content-style="modalStyleBig"
    >
      <button class="modal__close" @click="showModal = false">
        <div><MDBIcon style="color: #696969" icon="times" size="2x" /></div>
      </button>
      <div class="modal__title">
        <span>{{ detail.heading }}</span>
        <span style="font-weight: 200; font-size: 15px"
          ><small>_____{{ detail.vorname }} {{ detail.nachname }}</small></span
        >
      </div>
      <div class="modal__content">
        <div class="container">
          <div class="row">
            <div :class="detailStyle">{{ detail.text }}</div>
            <div :class="detailStyle">
              <div class="position-relative">
                <img
                  :src="
                    'https://faire-lehre.de/faire_lehre_file/files/' +
                    detail.image
                  "
                  class="w-100 bg-image"
                  style="border-radius: 2%"
                />
                <div
                  style="font-size: 16px"
                  class="rounded-3 px-2 m-2 bg-dark bg-gradient text-white bg-opacity-75 position-absolute bottom-0 end-0"
                >
                  {{ detail.beruf }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
// @ is an alias to /src

import { MDBCol, MDBRow, MDBContainer, MDBIcon } from "mdb-vue-ui-kit";
import StatementDataService from "../services/StatementDataService";
import { VueFinalModal } from "vue-final-modal";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "HomeView",
  components: {
    MDBCol,
    MDBRow,
    MDBContainer,
    MDBIcon,
    VueFinalModal,
    Loading,
  },
  data() {
    return {
      msg: "",
      statements: {},
      showModal: false,
      modalStyle: "modal-content w-50",
      detailStyle: "col-sm-6",
      modalStyleBig: "",
      detail: {},
      mounted: false,
      numberOfColumns: 5,
      window: {
        width: 0,
        height: 0,
      },
      isLoading: true,
    };
  },
  methods: {
    showDetails(statement) {
      //console.log(statement.text);
      this.detail = statement;
      this.showModal = true;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 340) {
        this.numberOfColumns = 1;
        this.modalStyle = "modal-content w-100";
        this.detailStyle = "col-sm-6";
        this.modalStyleBig = "width: 100% !important;";
      }
      if (this.window.width >= 340) {
        this.numberOfColumns = 2;
        this.modalStyle = "modal-content w-100";
        this.detailStyle = "w-50 col-sm-6";
        this.modalStyleBig = "width: 100% !important;";
      }
      if (this.window.width >= 768) {
        this.numberOfColumns = 3;
        this.modalStyle = "modal-content w-75";
        this.detailStyle = "col-sm-6";
        this.modalStyleBig = "width: 75% !important;";
      }
      if (this.window.width >= 992) {
        this.numberOfColumns = 4;
        this.modalStyle = "modal-content w-75";
        this.detailStyle = "col-sm-6";
        this.modalStyleBig = "width: 75% !important;";
      }
      if (this.window.width >= 1200) {
        this.numberOfColumns = 5;
        this.modalStyle = "modal-content";
        this.modalStyleBig = "width: 60% !important;";
        this.detailStyle = "col-md-6";
      }
    },

    async retrieveStatements() {
      await StatementDataService.getAllPublished()
        .then((response) => {
          this.statements = response.data;
          this.mounted = true;
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
          //console.log("HIER", response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  async mounted() {
    window.scrollTo(0, 0);
  },
  async created() {
    await this.retrieveStatements();
    this.modalStyle = "modal-content w-50";
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  computed: {
    gridStyle() {
      return {
        gridTemplateColumns: `repeat(${this.numberOfColumns}, minmax(100px, 1fr))`,
      };
    },
  },
};
</script>
<style>
.card-list {
  display: grid;
  grid-gap: 5px;
}

.card-item {
  background-color: rgb(173, 223, 171);
  padding: 0;
}

.img-thumbnail {
  padding: 0;
  background-color: #fff;
  border: 0;
  border-radius: 0;
}

.fixed-top {
  z-index: 900;
}
</style>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 25px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 20px;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

button {
  background-color: transparent;
  background-image: none;
  border: 0 solid #e2e8f0;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
