import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://faire-lehre.de/faire_lehre_api/api";
axios.defaults.headers = { "Content-type": "application/json" };

class TutorialDataService {
  getAllPublished() {
    return axios.get("/statements/published");
  }

  admin(passwd) {
    return axios.post("/statements/admin", passwd);
  }

  get(id) {
    return axios.get(`/statements/${id}`);
  }

  create(data) {
    return axios.post("/statements", data);
  }

  update(id, data) {
    return axios.put(`/statements/${id}`, data);
  }

  delete(id) {
    return axios.delete(`/statements/${id}`);
  }

  deleteAll() {
    return axios.delete(`/statements`);
  }

  findByName(name) {
    return axios.get(`/statements?name=${name}`);
  }
}

export default new TutorialDataService();
