<template>
  <div ref="root" style="padding-top: 10px">
    <MDBContainer style="margin-top: 60px">
      <MDBRow v-if="form_visible" class="p-4">
        <MDBCol sm="6" class="pb-3 pb-lg-0">
          <div style="height: 100%" class="position-relative">
            <div
              v-if="file_msg"
              class="p-3 mb-2 rounded bg-danger text-white position-absolute top-50 start-50 translate-middle"
            >
              Bitte erst ein Bild hochladen
            </div>
            <div
              v-if="file_msg_size"
              class="p-3 mb-2 bg-danger rounded text-white position-absolute top-50 start-50 translate-middle text-center"
            >
              Bitte ein Bild mit min. 700 x 700 Pixel hochladen
            </div>
            <div
              id="my-form"
              class="dropzone d-flex justify-content-center align-items-center"
            ></div>
          </div>
        </MDBCol>
        <MDBCol sm="6">
          <MDBRow
            tag="form"
            class="g-3 needs-validation"
            novalidate
            @submit.prevent="checkForm"
          >
            <MDBCol md="6">
              <MDBInput
                label="Vorname"
                v-model="form_first_name"
                invalidFeedback="Bitte gib deinen Vornamen an"
                required
              />
            </MDBCol>

            <MDBCol md="6">
              <MDBInput
                label="Nachname"
                v-model="form_last_name"
                invalidFeedback="Bitte gib deinen Nachnamen an"
                required
              />
            </MDBCol>

            <MDBCol md="6">
              <MDBInput
                label="Mail"
                type="email"
                v-model="form_mail"
                invalidFeedback="Bitte gib eine Mail an"
                required
              />
            </MDBCol>

            <MDBCol md="6">
              <select
                v-model="form_beruf"
                required
                class="form-select"
                aria-label="Bitte Beruf wählen"
              >
                <option value="" hidden>Bitte Beruf wählen</option>
                <option value="Professor:in">Professor:in</option>
                <option value="Lehrbeauftragte:r">Lehrbeauftragte:r</option>
                <option value="Künstlerische:r Mitarbeiter:in">
                  Künstlerische:r Mitarbeiter:in
                </option>
                <option value="Student:in">Student:in</option>
                <option value="Musiker:in">Musiker:in</option>
                <option value="Sonstiges">Sonstiges</option>
              </select>
            </MDBCol>

            <MDBCol md="12">
              <MDBInput
                label="Kurz und knapp (Überschrift)"
                v-model="form_heading"
                invalidFeedback="Bitte gib eine Überschrift an"
                required
              />
            </MDBCol>

            <MDBCol md="12">
              <MDBTextarea
                type="text"
                label="Warum ist es für euch wichtig für eine faire Lehre einzustehen?"
                rows="4"
                v-model="form_statement"
                wrapperClass="mb-4"
                required
                invalidFeedback="Bitte gib ein Statement ab"
              />
            </MDBCol>

            <MDBRow class="mb-4">
              <MDBCol>
                <!-- Checkbox -->
                <MDBCheckbox
                  label="Ich bin mit dem
               Datenschutz einverstanden. Weiterhin stimme ich mit dem Absenden des Bildes in die Nutzung durch das Team von faire-lehre.de für die Homepage und die offiziellen Kanäle in den sozialen Netzwerken ein."
                  id="form1DSGVOCheck"
                  v-model="form_dsgvo"
                  wrapperClass="mb-3 mb-md-0"
                  required
                />
              </MDBCol>
            </MDBRow>
            <MDBCol col="12">
              <MDBBtn
                color="success"
                class="btn-lg btn-block"
                type="submit"
                @click="mitmachen()"
                >Mitmachen</MDBBtn
              >
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <MDBRow v-if="danke_visible" class="p-4">
        <MDBCard class="bg-success bg-opacity-25 p-3">
          <MDBCardBody class="d-flex justify-content-center align-items-center"
            ><MDBIcon icon="check" size="3x" class="pe-3" />
            Vielen Dank für dein Engagement! Wir werden deinen Eintrag prüfen
            und schnellstmöglich veröffentlichen. :) Über den Status wirst du
            per Mail informiert.
          </MDBCardBody>
        </MDBCard>
      </MDBRow>
    </MDBContainer>
  </div>
</template>

<script>
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBTextarea,
  MDBCard,
  MDBCardBody,
  MDBIcon,
} from "mdb-vue-ui-kit";
import StatementDataService from "../services/StatementDataService";
import { ref, onMounted } from "vue";
import Dropzone from "dropzone";

export default {
  name: "MitmachenView",
  components: {
    MDBCol,
    MDBRow,
    MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBTextarea,
    MDBCard,
    MDBCardBody,
    MDBIcon,
  },
  setup() {
    const root = ref(null);

    onMounted(() => {});

    const checkForm = (e) => {
      e.target.classList.add("was-validated");
    };
    return {
      checkForm,
      root,
    };
  },
  data() {
    return {
      form_first_name: "",
      form_last_name: "",
      form_mail: "",
      form_beruf: "",
      form_heading: "",
      form_statement: "",
      form_dsgvo: false,
      form_visible: true,
      danke_visible: false,
      filename: "",
      file_msg: false,
      file_msg_size: false,
    };
  },
  methods: {
    showFileError() {
      this.file_msg = true;
      setTimeout(() => {
        this.file_msg = false;
      }, 4000);
    },

    showFileErrorSize() {
      this.file_msg_size = true;
      setTimeout(() => {
        this.file_msg_size = false;
      }, 4000);
    },

    mitmachen() {
      const statement = {
        vorname: this.form_first_name,
        nachname: this.form_last_name,
        text: this.form_statement,
        image: this.filename,
        heading: this.form_heading,
        email: this.form_mail,
        beruf: this.form_beruf,
      };

      console.log(statement);
      if (this.filename === "") {
        this.showFileError();
      } else {
        if (
          this.form_first_name != "" &&
          this.form_last_name != "" &&
          this.form_statement != "" &&
          this.form_heading != "" &&
          this.form_mail != "" &&
          this.form_beruf != "" &&
          this.form_dsgvo === true
        ) {
          StatementDataService.create(statement)
            .then((response) => {
              console.log("Ergebnis", response.data);
              this.form_first_name = "";
              this.form_last_name = "";
              this.form_statement = "";
              this.form_heading = "";
              this.filename = "";
              this.form_mail = "";
              this.form_beruf = "";
              this.form_dsgvo = false;
              this.form_visible = false;
              this.danke_visible = true;
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    let myDropzone = new Dropzone("#my-form", {
      url: "https://faire-lehre.de/faire_lehre_file/upload/",
      thumbnailMethod: "crop",
      addRemoveLinks: "true",
      maxFiles: 1,
      resizeWidth: 700,
      resizeHeight: 700,
      resizeMimeType: "image/jpeg",
      resizeQuality: 0.8,
      resizeMethod: "crop",
      acceptedFiles: "image/*",
      thumbnailWidth: 240,
      thumbnailHeight: 240,
      renameFile: function (file) {
        console.log(file.name);
        const newName = new Date().getTime() + ".jpg";
        return newName;
      },
      dictInvalidFileType: "Du kannst Dateien dieser Art nicht hochladen.",
      dictRemoveFile: "Löschen",
      dictCancelUpload: "Upload abbrechen",
      dictDefaultMessage:
        "Lade hier ein Bild von dir, durch Hineinziehen oder Klicken, hoch",
    });
    myDropzone.on("addedfile", (file) => {
      console.log("File added:", file);
    });
    myDropzone.on("maxfilesexceeded", function (file) {
      myDropzone.removeFile(file);
    });
    myDropzone.on("success", (file) => {
      console.log("Erfolgreich hochgeladen:", file.upload.filename);
      this.filename = file.upload.filename;
      if (file.width < 700 || file.height < 700) {
        this.showFileErrorSize();
        this.filename = "";
        myDropzone.removeFile(file);
      }
    });
  },
  created() {},
};
</script>
<style>
.form-select {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.dropzone {
  height: 100%;
}

#form1DSGVOCheck + label {
  width: 97%;
}

#my-form {
  border: 2px dashed #00b74a;
}

#my-form .dz-preview .dz-image {
  width: 240px;
  height: 240px;
}
</style>
