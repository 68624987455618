<template>
  <div>
    <agile :initial-slide="0" v-if="mounted" :autoplay-speed="10000" autoplay>
      <div
        v-for="statement in statements"
        :key="statement.id"
        class="slide bg-image"
      >
        <div
          @click="showDetails(statement)"
          class="slider-title"
          style="text-align: center; cursor: pointer"
        >
          <h4>{{ statement.heading }}</h4>

          <div>
            <p style="font-style: italic">
              {{ statement.vorname }} {{ statement.nachname }}
            </p>
          </div>

          <div style="width: 100%">
            <p
              style="
                -webkit-line-clamp: 6;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
              "
            >
              {{ statement.text }}
            </p>
          </div>
        </div>

        <div
          style="font-size: 14px"
          class="rounded-3 px-2 m-2 bg-dark bg-gradient text-white bg-opacity-75 position-absolute bottom-0 end-0"
        >
          {{ statement.beruf }}
        </div>

        <img
          class="w-100"
          :src="
            'https://faire-lehre.de/faire_lehre_file/files/' + statement.image
          "
        />
      </div>

      <template v-slot:prevButton><i class="fas fa-chevron-left"></i></template>
      <template v-slot:nextButton
        ><i class="fas fa-chevron-right"></i
      ></template>
    </agile>

    <vue-final-modal
      v-if="showModal"
      v-model="showModal"
      classes="modal-container"
      :content-class="modalStyle"
      :content-style="modalStyleBig"
    >
      <button class="modal__close" @click="showModal = false">
        <div><MDBIcon style="color: #696969" icon="times" size="2x" /></div>
      </button>
      <div class="modal__title">
        <span>{{ detail.heading }}</span>
        <span style="font-weight: 200; font-size: 15px"
          ><small>_____{{ detail.vorname }} {{ detail.nachname }}</small></span
        >
      </div>
      <div class="modal__content">
        <div class="container">
          <MDBRow>
            <MDBCol md="6">
              <div>{{ detail.text }}</div>
            </MDBCol>
            <MDBCol md="6">
              <div>
                <div class="position-relative">
                  <img
                    :src="
                      'https://faire-lehre.de/faire_lehre_file/files/' +
                      detail.image
                    "
                    class="w-100 bg-image"
                    style="border-radius: 2%"
                  />
                  <div
                    style="font-size: 16px"
                    class="rounded-3 px-2 m-2 bg-dark bg-gradient text-white bg-opacity-75 position-absolute bottom-0 end-0"
                  >
                    {{ detail.beruf }}
                  </div>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import "vue-agile/dist/VueAgile.css";
import StatementDataService from "../services/StatementDataService";
import { VueFinalModal } from "vue-final-modal";

import { MDBCol, MDBRow, MDBIcon } from "mdb-vue-ui-kit";

import { VueAgile } from "vue-agile";

export default {
  components: {
    agile: VueAgile,
    VueFinalModal,
    MDBCol,
    MDBRow,
    MDBIcon,
  },
  data() {
    return {
      statements: {},
      mounted: false,
      showModal: false,
      modalStyle: "modal-content w-75",
      detailStyle: "col-md-6",
      modalStyleBig: "",
      detail: {},
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 340) {
        this.modalStyle = "modal-content w-100";
        this.detailStyle = "col-md-6";
        this.modalStyleBig = "width: 100% !important;";
      }
      if (this.window.width >= 340) {
        this.modalStyle = "modal-content w-100";
        this.detailStyle = "w-50 col-md-6";
        this.modalStyleBig = "width: 100% !important;";
      }
      if (this.window.width >= 768) {
        this.modalStyle = "modal-content w-75";
        this.detailStyle = "col-md-6";
        this.modalStyleBig = "width: 75% !important;";
      }
      if (this.window.width >= 992) {
        this.modalStyle = "modal-content w-75";
        this.detailStyle = "col-md-6";
        this.modalStyleBig = "width: 75% !important;";
      }
      if (this.window.width >= 1200) {
        this.modalStyle = "modal-content";
        this.modalStyleBig = "width: 60% !important;";
        this.detailStyle = "col-md-6";
      }
    },

    showDetails(statement) {
      //console.log(statement.text);
      this.detail = statement;
      this.showModal = true;
    },

    async retrieveStatements() {
      await StatementDataService.getAllPublished()
        .then((response) => {
          this.statements = response.data.filter((c) => c.favorit === true);
          this.mounted = true;
          console.log(this.statements);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  async created() {
    await this.retrieveStatements();
    this.modalStyle = "modal-content w-50";
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
};
</script>

<style>
.slider-title {
  padding-top: 20px;
  padding-right: 80px;
  padding-left: 80px;
  position: absolute;
  display: block;
  justify-content: center;

  width: 100%;
  background-color: hsla(25, 25%, 25%, 0.25);
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  width: 80px;
}
.agile__nav-button:hover {
  background-color: rgba(0, 0, 0, 0);
  opacity: 1;
}
.agile__nav-button--prev {
  left: 0;
}
.agile__nav-button--next {
  right: 0;
}
.agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #fff;
}

.slide {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  max-height: 400px;
  height: 400px;

  background: #000;

  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide img {
  max-width: 100%;
}
</style>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 25px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 20px;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

button {
  background-color: transparent;
  background-image: none;
  border: 0 solid #e2e8f0;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
