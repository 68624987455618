//import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from "vue-router";

import HomeView from "../views/HomeView.vue";
import MitmachenView from "../views/MitmachenView.vue";
import DatenschutzView from "../views/DatenschutzView.vue";
import AdminView from "../views/AdminView.vue";
import InitiativeView from "../views/InitiativeView.vue";
import DabeiView from "../views/DabeiView.vue";
import ImpressumView from "../views/ImpressumView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/mitmachen",
    name: "mitmachen",
    component: MitmachenView,
  },
  {
    path: "/wir-sind-dabei",
    name: "wir-sind-dabei",
    component: DabeiView,
  },
  {
    path: "/initiative",
    name: "initiative",
    component: InitiativeView,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: DatenschutzView,
  },

  {
    path: "/impressum",
    name: "impressum",
    component: ImpressumView,
  },

  {
    path: "/ueber",
    name: "ueber",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AboutView.vue"),
  },
];

// history: createWebHistory(process.env.BASE_URL),

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
